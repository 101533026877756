import ApiService from "@/services";
import { handleApiError } from "../helpers";

const state = {
  propertyData: null,
  properties: [],
  fetching: false,
  fetchingAvailability: false,
};

const getters = {};

const mutations = {
  setProperties(state, data) {
    state.properties = data;
    state.fetching = false;
  },
  setProperty(state, data) {
    state.propertyData = data;
    state.fetching = false;
  },
  setFetching(state, data) {
    state.fetching = data;
  },
  setFetchingAvailability(state, data) {
    state.fetchingAvailability = data;
  },
};

const actions = {
  async searchProperties({ commit }, params) {
    try {
      commit("setFetching", true);
      const response = await ApiService.get(`properties/search`, { params });
      commit("setProperties", response.data.data);
      return response?.data?.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async countProperties() {
    try {
      return await ApiService.get("properties/count");
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async findEhotelAvailability({ commit }, payload) {
    try {
      commit("setFetchingAvailability", true);
      const data = await ApiService.get("properties/ehotel/availability", {
        params: payload,
      });
      return data;
    } catch (error) {
      throw handleApiError(error);
    } finally {
      commit("setFetchingAvailability", false);
    }
  },
  async findCultswitchAvailability({ commit }, payload) {
    try {
      commit("setFetchingAvailability", true);
      const data = await ApiService.get("properties/cultswitch/availability", {
        params: payload,
      });
      return data;
    } catch (error) {
      throw handleApiError(error);
    } finally {
      commit("setFetchingAvailability", false);
    }
  },
  async getPropertyById({ commit }, id) {
    try {
      commit("setFetching", true);
      const response = await ApiService.get(`properties/${id}`);
      commit("setProperty", response.data.data);
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async getPropertyCalendarPrice(_, params) {
    try {
      const { data } = await ApiService.get(`properties/calendar-price`, {
        params,
      });
      return data.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
  async getAllFacilities() {
    try {
      const { data } = await ApiService.get("properties/facilities");
      return data.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
