import Vue from "vue";

import Router from "vue-router";
Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: "/",
      name: "landing-page",
      component: () => import("@/pages/Hotel.vue"),
    },
    {
      path: "/listing",
      name: "listing",
      component: () => import("@/pages/HotelListing.vue"),
    },
    {
      path: "/how-gloveler-works",
      name: "how-gloveler-works",
      component: () => import("@/pages/HowGlovelerWorks.vue"),
    },
    {
      path: "/about-us",
      name: "about-us",
      component: () => import("@/pages/AboutUs.vue"),
    },
    {
      path: "/terms-of-use",
      name: "terms-of-use",
      component: () => import("@/pages/TermsOfUse.vue"),
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("@/pages/PrivacyPolicy.vue"),
    },
    {
      path: "/legal-notice",
      name: "legal-notice",
      component: () => import("@/pages/LegalNotice.vue"),
    },
    {
      path: "/list-accomodation",
      name: "list-accomodation",
      component: () => import("@/pages/ListAccomodation.vue"),
    },
    {
      path: "/build-booking-engine",
      name: "build-booking-engine",
      component: () => import("@/pages/BuildBookingEngine.vue"),
    },
    {
      path: "/listing/:id",
      name: "listing-details",
      component: () => import("@/pages/HotelListingItem.vue"),
    },
    {
      path: "/listing/:id/reservation",
      name: "listing-reservation",
      component: () => import("@/pages/HotelReservation.vue"),
    },
    {
      path: "/payment_page",
      name: "payment",
      component: () => import("@/pages/Payment.vue"),
    },
    {
      path: "/payment_update",
      name: "payment-update",
      component: () => import("@/pages/PaymentUpdatePage.vue"),
    },
    {
      path: "/reservation_success",
      name: "reservation-success",
      component: () => import("@/pages/HotelReservationSuccess.vue"),
    },
    {
      path: "/reservation_cancellation",
      name: "reservation-cancellation",
      component: () => import("@/pages/HotelReservationCancellation.vue"),
    },
    {
      path: "/listing_map",
      name: "listing-map",
      component: () => import("@/pages/HotelListingMap.vue"),
    },
  ],
});

export default router;
