<template>
  <div id="footer-scroll">
    <section class="property-owner" v-if="$route.name === 'landing-page'">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title color-fff">
              {{ $t("footer.areYouPropertyOwner") }}
            </h2>
            <p>{{ $t("footer.areYouPropertyOwnerContent") }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center list-property">
            <a href="#">{{ $t("footer.listYourProperty") }}</a>
            <a href="#"> {{ $t("footer.getInTouch") }} </a>
          </div>
        </div>
      </div>
    </section>

    <section
      class="follow"
      v-if="
        !['terms-of-use', 'legal-notice', 'privacy-policy'].includes(
          $route.name
        )
      "
    >
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-12 text-center">
            <h2 class="section-title color-444">
              {{ $t("footer.followGloveler") }}
            </h2>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <ul class="social-icons d-flex">
              <li>
                <a href="#"
                  ><i class="fa fa-facebook" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
              </li>
              <li>
                <a href="#"
                  ><i class="fa fa-google-plus" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-rss" aria-hidden="true"></i></a>
              </li>
            </ul>
            <p class="text-center">
              <a href="#" class="contact-us">{{ $t("footer.contactUs") }}</a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="main-footer">
      <div class="container-fluid plr-80">
        <div class="row">
          <div class="col-md-3 footer-logo col-lg-3">
            <a href="#">Gloveler</a>
            <p class="copyright">
              © {{ $t("footer.copyright") }} {{ new Date().getFullYear() }}
            </p>
          </div>
          <div class="col footer-list">
            <h4>{{ $t("footer.company") }}</h4>
            <ul>
              <li>
                <a href="/about-us">{{ $t("footer.aboutUs") }}</a>
              </li>
            </ul>
          </div>
          <div class="col footer-list">
            <h4>{{ $t("footer.legal") }}</h4>
            <ul>
              <li>
                <a href="/terms-of-use">{{ $t("footer.termsOfUse") }}</a>
              </li>
              <li>
                <a href="/privacy-policy">{{ $t("footer.privacyPolicy") }}</a>
              </li>
              <li>
                <a href="/legal-notice">{{ $t("footer.legalNotice") }}</a>
              </li>
            </ul>
          </div>
          <div class="col footer-list">
            <h4>{{ $t("footer.information") }}</h4>
            <ul>
              <li>
                <a href="#">{{ $t("footer.contact") }}</a>
              </li>
            </ul>
          </div>
          <div class="col-md-3 footer-list">
            <h4>Gloveler</h4>
            <ul>
              <li>
                <a href="/build-booking-engine">{{
                  $t("footer.bedAndBreakfast")
                }}</a>
              </li>
              <li>
                <a href="#">{{ $t("footer.glovelerBlog") }}</a>
              </li>
              <li>
                <a href="/list-accomodation">{{
                  $t("footer.listAccomodationFree")
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mob-block">
          <div class="col-12 text-center">
            <p class="copyright">
              © {{ $t("footer.copyright") }} {{ new Date().getFullYear() }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
