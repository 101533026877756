import i18n from "../i18n";

const replaceVariableFormat = (text) =>
  text
    .replace(/{{/g, "{")
    .replace(/}}/g, "}")
    .replace(/<span translate="no">|<\/span>/g, "");

const updateNestedObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "string") {
      /* eslint-disable no-param-reassign */
      obj[key] = replaceVariableFormat(obj[key]);
    } else if (typeof obj[key] === "object") {
      updateNestedObject(obj[key]);
    }
  });
};

const TranslationHelper = {
  async updateTranslations(router, language = "en", force = false) {
    this.$store = this.$store ?? router.app.$store;
    const projectsPages = {
      gloveler: [
        "home",
        "header",
        "footer",
        "listing",
        "rating",
        "aboutUs",
        "property",
        "termsOfUse",
        "statistics",
        "breadcrumbs",
        "legalNotice",
        "reservation",
        "listingFilter",
        "privacyPolicy",
        "howGlovelerWorks",
        "listAccomodation",
        "buildBookingEngine",
      ],
    };

    let data = await this.$store.getters["translation/getLanguages"];
    if (!data || force) {
      const projects = [];
      Object.entries(projectsPages).forEach(([key, pages]) =>
        projects.push({ key, pages })
      );

      data = await this.$store.dispatch("translation/getTranslate", {
        projects,
        language,
        force,
      });
    }

    this.$store.commit("translation/getTranslation", { ...data });
    if (data?.translations) {
      let locales = {};
      const { translations } = data;

      translations.forEach((trans) => {
        Object.keys(projectsPages).forEach((project) => {
          if (project in trans) {
            Object.entries(trans[project]).forEach(([k, v]) => {
              locales[k] = k in locales ? { ...locales[k], ...v } : { ...v };
            });
          }
        });
      });

      locales = JSON.parse(JSON.stringify(locales));

      Object.keys(locales).forEach((locale) => {
        updateNestedObject(locales[locale]);
        i18n.setLocaleMessage(locale, { ...locales[locale] });
      });
    } else {
      const locales = require.context(
        "../i18n/locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      );
      const messages = {};
      locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          messages[locale] = locales(key);
          i18n.setLocaleMessage(locale, messages[locale]);
        }
      });
    }
  },
};

export default TranslationHelper;
