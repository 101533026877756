<template>
  <div class="app-spinner-wrapper" :class="{'small': small, 'very-small': verySmall}">
    <b-spinner class="app-spinner" type="grow" label="Spinning"></b-spinner>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    verySmall: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style scoped>
.app-spinner-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-spinner-wrapper.small {
  height: 50vh !important;
}
.app-spinner-wrapper.very-small {
  height: 100px !important;
}
.app-spinner {
  background: #ff762b;
  width: 50px;
  height: 50px;
}
.app-spinner-wrapper.very-small .app-spinner {
  width: 30px !important;
  height: 30px !important;
}
</style>
